import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
	},
	{
		path: '**',
		loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
